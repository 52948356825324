import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import './UpdatedHomeFAQs.scss';
import { UpdatedPokerFaq } from '../../FAQs/faqs-list-interface';

import FAQ from '../../FAQs/FAQ';
import useFaqExpansion from '../../../hooks/useFaqExpansion';
import { POKER_FAQS } from '../../internal-links';
import { Container } from 'react-bootstrap';

interface Props {
    faqs?: UpdatedPokerFaq[][];
    allFaqText?: string;
    hideAllFaqsLink?: boolean;
    faqTitle?: string;
    showClubs?: boolean
    style?: React.CSSProperties
    color?: string;
    h2LineHeight?: string;
}

const UpdatedPokerFAQs: React.FC<Props> = ({
    faqs,
    allFaqText,
    faqTitle,
    hideAllFaqsLink,
    color,
    showClubs,
}) => {
    const { expandedFaq, setExpandedFaq } = useFaqExpansion();
    const [defaultFaqs, setDefaultFaqs] = useState<UpdatedPokerFaq[][]>([[], [], [], [], [], [], []]);

    useEffect(() => {
        import('../../FAQs/faqs-list')
            .then((module) => {
                // Type assertion to match the expected structure
                const faqsList = module.pokerfaqs as UpdatedPokerFaq[];
                console.log('pokerfaqsList:', faqsList); // Log to check data
                const splitFaqs = [
                    faqsList.slice(0, 8),
                    faqsList.slice(31, 34),
                    faqsList.slice(35, 38),
                    faqsList.slice(39, 42),
                    faqsList.slice(43, 47),
                    faqsList.slice(48, 53),
                ];
                setDefaultFaqs(splitFaqs);
            })
            .catch(err => {
                console.error('Failed to load faqs-list:', err);
            });
    }, []);

    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index: number) => {
        setActiveTab(index);
    };

    const faqsList = faqs?.length ? faqs[activeTab] : defaultFaqs[activeTab];
    const faqClassNames = `FAQ${showClubs ? ' show-clubs' : ''}`;
    return (
        <div id="FAQ" className={faqClassNames}>
            <Container>
                <h2>{faqTitle || 'FAQ'}</h2>

                <div className="content-container">
                    <div className="tab-navigation">
                        {['Generic', 'Poker Strategies', 'Poker Tournaments', 'Poker Software', 'Poker Download', 'Payments Bonuses'].map((tab, index) => (
                            <button
                                key={index}
                                className={`tab-button ${activeTab === index ? 'active' : ''}`}
                                onClick={() => handleTabClick(index)}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>
                    <div className="content">
                        <div className="faqs">
                            {faqsList && faqsList.length > 0 ? (
                                faqsList.map((f, i) => (
                                    <React.Fragment key={`${f.question}-${i}`}>
                                        <FAQ
                                            question={f.question}
                                            answer={f.answer}
                                            answerElement={f.answerElement}
                                            expanded={expandedFaq === i}
                                            setExpanded={setExpandedFaq}
                                            questinNumber={i}
                                            color={color}
                                        />
                                        {i !== faqsList.length - 1 && <div className="separator" />}
                                    </React.Fragment>
                                ))
                            ) : (
                                <p>No FAQs available</p>
                            )}
                        </div>

                        {!hideAllFaqsLink && (
                            <Link to={POKER_FAQS} className="cta">
                                <div>{allFaqText || 'GO TO FAQ PAGE'}</div>
                                <div className="right-icon">
                                    <StaticImage
                                        src="../../../images/index/common/arrow-right.png"
                                        alt="more faqs"
                                        className="arrow"
                                        width={24}
                                    />
                                </div>
                            </Link>
                        )}

                        <>
                            <div className="club-left">
                                <StaticImage
                                    src="../../../images/index/common/club.png"
                                    alt="club left"
                                    className="club-img"
                                    quality={100}
                                />
                            </div>
                            <div className="club-right">
                                <StaticImage
                                    src="../../../images/index/common/club.png"
                                    alt="club right"
                                    className="club-img"
                                    quality={100}
                                />
                            </div>
                        </>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default UpdatedPokerFAQs;
