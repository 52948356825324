import React, { useEffect, useState } from 'react';
import './Authorsupdate.scss';

// Define the type for an author
interface Author {
    name: string;
}

// Define the props type
interface AuthorsUpdateProps {
    pageType: 'poker' | 'rummy';
}

const AuthorsUpdate: React.FC<AuthorsUpdateProps> = ({ pageType }) => {
    // Define the type of state using Author[]
    const [lastUpdatedDate, setLastUpdatedDate] = useState('');
    const [authors, setAuthors] = useState<Author[]>([]);

    useEffect(() => {
        const now = new Date();
        const formattedDate = now.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
        });
        setLastUpdatedDate(formattedDate);

        const updatedAuthors: Author[] = [
            { name: 'Shahla Jabbeen' },
            { name: pageType === 'poker' ? 'Kanchan Sharma' : 'Rohan Mathawan' }
        ];
        setAuthors(updatedAuthors);
    }, [pageType]);

    return (
        <div className="about-authors">
            <div className='inner-div'>
                <div className="timeline">
                    <div className="timeline-event">
                        <span className="dot filled"></span>
                        <p className="timeline-text">Current Version</p>
                    </div>
                    <div className="timeline-event">
                        <span className="dot empty"></span>
                        <p className="timeline-text">{lastUpdatedDate}</p>
                    </div>
                    <div className="vertical-line"></div> {/* Vertical line */}
                </div>
                <div className="author-list">
                    <div className="author-item">
                        <strong>Written by</strong> <p>{authors[0]?.name}</p>
                    </div>
                    <div className="author-item">
                        <strong>Reviewed by</strong> <p>{authors[1]?.name}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuthorsUpdate;
