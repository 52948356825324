import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  POKER_HINDI,
  POKER as POKER_LINK,
} from '../../components/internal-links'
import { POKER } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generatePokerFaqSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generatePokerWebPageSchema,
  generateWebsiteSchema,
} from '../../lib/generate-schema'

import loadable from '@loadable/component'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'
import TrustFlowWidget from '../../components/Trust-Flow-Widget/Trust-flow-widget'
import BannerCarousel from '../../components/Design2.0/HomePage/Features/Carousel/BannerCarousel'
import StaySafeAndCertified from '../../components/Design2.0/common/StaySafeAndCertified/StaySafeAndCertified'
import TestimonialsContent from '../../components/Design2.0/common/Testimonials/Testimonials'
import OurBlogs from '../../components/Design2.0/common/OurBlogs/OurBlogs'
import AboutGetMega from '../../components/Design2.0/HomePage/AboutGetMega/AboutGetMega'
import TypesOfPoker from '../../components/Design2.0/PokerPage/Features/TypesofPoker/TypesOfPoker'
import CommonTerms from '../../components/Design2.0/PokerPage/Features/CommonTerms/CommonTerms'
import TypesDescription from '../../components/Design2.0/PokerPage/Features/TypesofPoker/TypesDescription'
import MixedTypesofGames from '../../components/Design2.0/PokerPage/Features/MixedGames/MixedTypesofGames'
import TypeofCardGames from '../../components/Design2.0/PokerPage/Features/TypeofCardGames/TypeofCardGames'

import MainBanner, {
  PageNameEnum,
} from '../../components/Design2.0/HomePage/MainBanner/MainBanner'
import FeaturesSection from '../../components/Design2.0/PokerPage/Features/TypesofPoker/FeatureSection'
import HeaderSection from '../../components/Design2.0/PokerPage/Features/TypesofPoker/HeaderSection'
import AboutAuthors from '../../components/AboutAuthors/AboutAuthors'
import Authorsupdate from '../../components/AboutAuthors/Authorsupdate'

import PokerMainBanner from '../../components/Design2.0/PokerPage/PokerMainBanner'
import UpdatedPokerFAQs from '../../components/Index/HomeFAQs/UpdatedPokerFAQs'

interface PokerProps {
  lang?: 'english' | 'hindi'
}

const Poker: React.FC<PokerProps> = ({ lang = 'english' }) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
        allGhostPost(filter: { slug: { eq: "poker-product-page" } }) {
          nodes {
            html
            title
            meta_title
            meta_description
          }
        }
      }
    `
  )

  const title = data.allGhostPost.nodes[0]?.title
  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  const SITE_URL = data.site.siteMetadata.siteUrl

  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: title || 'Poker', // Fallback title
      url: POKER_LINK,
    },
  ]

  return (
    <Layout
      apkLink={POKER}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
      customLogo
      pageName="/poker"
      langPost={[
        {
          languageName: 'english',
          pageSlug: `${SITE_URL}${POKER_LINK}`,
        },
        {
          languageName: 'hindi',
          pageSlug: `${SITE_URL}${POKER_HINDI}`,
        },
      ]}
      headerStyles={{
        backgroundColor: 'transparent',
      }}
    >
      <Helmet>
        {process.env.GATSBY_STAGING === 'true' && (
          <meta name="robots" content="noindex" />
        )}
        {/* Alternate links for different languages */}
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_LINK}`}
          hrefLang="en-in"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_LINK}`}
          hrefLang="x-default"
        />
        <link
          rel="alternate"
          href={`${SITE_URL}${POKER_HINDI}`}
          hrefLang="hi-in"
        />
      </Helmet>
      <SEO
        title={metaTitle}
        description={metaDescription}
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generatePokerFaqSchema()}
        webPageSchema={generatePokerWebPageSchema()}
        hideHrefLang // Hide hrefLang meta tags for SEO
      />
      {/* <PokerPage
        htmlContent={<RenderHTML html={data.allGhostPost.nodes[0]?.html} />}
        mainBannerTitle={title}
      /> */}

      <PokerMainBanner />
      <TrustFlowWidget />
      <AboutAuthors pageType="poker" />
      <HeaderSection />
      <FeaturesSection />
      <TypesOfPoker />
      <BannerCarousel />
      <StaySafeAndCertified style={{ height: '410px' }} />
      <CommonTerms />
      <TypesDescription />
      <MixedTypesofGames />
      <TypeofCardGames />
      <TestimonialsContent
        style={{ padding: '20px 0px' }}
        titleEng="Online Poker Reviews & Ratings"
      />
      <UpdatedPokerFAQs 
        showClubs= {true}
        style={{ paddingTop: '20px', paddingBottom: '30px' }}
        h2LineHeight="1.9"
        faqTitle="FAQs About Poker Online"/>
        
      <OurBlogs
        title="Online Poker Blogs"
        style={{ paddingTop: '20px', paddingBottom: '20px' }}
      />
      <AboutGetMega />
      <Authorsupdate pageType="poker"/>
    </Layout>
  )
}

export default Poker
