import React, { useState } from 'react';
import './AboutAuthors.scss';
import { graphql, useStaticQuery } from 'gatsby';

import { multiPageAuthor2 } from '../AuthorInfo/AuthorInfo-list';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface AboutAuthorsProps {
    pageType: 'poker' | 'rummy'; // Add more types if needed
}
const AboutAuthors: React.FC<AboutAuthorsProps> = ({ pageType }) => {
    const data = useStaticQuery(graphql`
    query {
        kanchanImage: file(relativePath: { eq: "Authors/kanchan-poker-author-desktop.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 200, quality: 90, layout: CONSTRAINED)
            }
        }
        rohanImage: file(relativePath: { eq: "Authors/rohan-author.jpeg" }) {
            childImageSharp {
                gatsbyImageData(width: 200, quality: 90, layout: CONSTRAINED)
            }
        }
    }
`);

    const kanchanImg = getImage(data.kanchanImage) as IGatsbyImageData;
    const rohanImg = getImage(data.rohanImage) as IGatsbyImageData;

    const [isHoveredShahla, setIsHoveredShahla] = useState(false);
    const [isHoveredKanchan, setIsHoveredKanchan] = useState(false);
    const [isHoveredRohan, setIsHoveredRohan] = useState(false);

    const renderKanchan = pageType === 'poker';
    const renderRohan = pageType === 'rummy';

    return (
        <div className="author-info-container">
            {/* Shahla's Author Section */}
            <div className="author-section"
                onMouseEnter={() => setIsHoveredShahla(true)}
                onMouseLeave={() => setIsHoveredShahla(false)}
            >
                <div className="author-details">
                    <div className="author-avatar">
                        <img src="/static/4346a60059a82459d7203d4f81bce55a/03f7f/shahla-author.webp" alt="Shahla Jabbeen" />
                    </div>
                    <div className="author-name">
                        <p>Written By:</p>
                        <a href="#" className="author-link">Shahla Jabbeen</a>
                    </div>
                </div>
                {isHoveredShahla && (
                    <div className="author-overlay">
                        <div className="overlay-header">
                            <img src="/static/4346a60059a82459d7203d4f81bce55a/03f7f/shahla-author.webp" alt="Shahla Jabbeen" className="overlay-avatar" />
                            <div className="overlay-name">
                                <p>Shahla Jabbeen</p>
                                <a
                                    href={multiPageAuthor2.linkedin}
                                    className="linkedin-icon"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0077B5" viewBox="0 0 24 24">
                                        <path d="M22.23 0H1.77C.792 0 0 .774 0 1.728v20.543C0 23.226.793 24 1.77 24h20.46C23.208 24 24 23.226 24 22.271V1.728C24 .774 23.208 0 22.23 0zM7.088 20.452H3.565V9.038h3.523v11.414zm-1.762-13.02a2.044 2.044 0 01-2.053-2.042 2.047 2.047 0 112.053 2.042zm15.914 13.02h-3.53v-5.727c0-1.368-.027-3.132-1.911-3.132-1.911 0-2.204 1.493-2.204 3.026v5.833h-3.53V9.038h3.39v1.557h.05c.473-.894 1.633-1.834 3.36-1.834 3.593 0 4.254 2.366 4.254 5.447v6.244z"></path>
                                    </svg>
                                </a>
                            </div>
                        </div>
                        <div className="overlay-description">
                            <p>
                                Shahla Jabbeen is an experienced content writer and editor specializing in poker and rummy blogs for GetMega. With over 3 years of experience in crafting engaging and informative articles, Shahla plays a pivotal role in shaping the platform's content strategy. Her deep understanding of the gaming industry, combined with her ability to communicate complex concepts in a reader-friendly manner, makes her an excellent writer.
                            </p>
                        </div>
                    </div>
                )}
            </div>

            {/* Conditionally render Kanchan's section */}
            {renderKanchan && (
                <div className="author-section"
                    onMouseEnter={() => setIsHoveredKanchan(true)}
                    onMouseLeave={() => setIsHoveredKanchan(false)}
                >
                    <div className="author-details">
                        <div className="author-avatar">
                            <GatsbyImage image={kanchanImg} alt="Kanchan Sharma" />
                        </div>
                        <div className="author-name">
                            <p>Reviewed By:</p>
                            <a href="#" className="author-link">Kanchan Sharma</a>
                        </div>
                    </div>
                    {isHoveredKanchan && (
                        <div className="author-overlay">
                            <div className="overlay-header">
                                <GatsbyImage image={kanchanImg} alt="Kanchan Sharma" className="overlay-avatar" />
                                <div className="overlay-name">
                                    <p>Kanchan Sharma</p>
                                    <a
                                        href="https://www.getmega.com/authors/kanchan-sharma/#"
                                        className="linkedin-icon"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0077B5" viewBox="0 0 24 24">
                                            <path d="M22.23 0H1.77C.792 0 0 .774 0 1.728v20.543C0 23.226.793 24 1.77 24h20.46C23.208 24 24 23.226 24 22.271V1.728C24 .774 23.208 0 22.23 0zM7.088 20.452H3.565V9.038h3.523v11.414zm-1.762-13.02a2.044 2.044 0 01-2.053-2.042 2.047 2.047 0 112.053 2.042zm15.914 13.02h-3.53v-5.727c0-1.368-.027-3.132-1.911-3.132-1.911 0-2.204 1.493-2.204 3.026v5.833h-3.53V9.038h3.39v1.557h.05c.473-.894 1.633-1.834 3.36-1.834 3.593 0 4.254 2.366 4.254 5.447v6.244z"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="overlay-description">
                                <p>
                                    Kanchan Sharma is an elite poker player and specialist with a keen eye for detail, currently reviewing over hundreds of poker blogs on GetMega each year. With a poker gaming journey spanning five years, during which she has participated in over 200 online tournaments and secured top positions in 30% of them, Kanchan brings invaluable real-world experience to her reviews. Her expertise and deep understanding of the game play a crucial role in ensuring the accuracy and quality of the content published on the platform. Her firsthand experience as a poker player allows her to provide insights that resonate deeply with the poker community.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {/* Conditionally render Rohan's section */}
            {renderRohan && (
                <div className="author-section"
                    onMouseEnter={() => setIsHoveredRohan(true)}
                    onMouseLeave={() => setIsHoveredRohan(false)}
                >
                    <div className="author-details">
                        <div className="author-avatar">
                            <GatsbyImage image={rohanImg} alt="Rohan Mathawan" />
                        </div>
                        <div className="author-name">
                            <p>Reviewed By:</p>
                            <a href="https://www.linkedin.com/in/rohan-mathawan-2a67a4141/" className="author-link" target="_blank" rel="noopener noreferrer">Rohan Mathawan</a>
                        </div>
                    </div>
                    {isHoveredRohan && (
                        <div className="author-overlay">
                            <div className="overlay-header">
                                <GatsbyImage image={rohanImg} alt="Rohan Mathawan" className="overlay-avatar" />
                                <div className="overlay-name">
                                    <p>Rohan Mathawan</p>
                                    <a
                                        href="https://www.linkedin.com/in/rohan-mathawan-2a67a4141/"
                                        className="linkedin-icon"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#0077B5" viewBox="0 0 24 24">
                                            <path d="M22.23 0H1.77C.792 0 0 .774 0 1.728v20.543C0 23.226.793 24 1.77 24h20.46C23.208 24 24 23.226 24 22.271V1.728C24 .774 23.208 0 22.23 0zM7.088 20.452H3.565V9.038h3.523v11.414zm-1.762-13.02a2.044 2.044 0 01-2.053-2.042 2.047 2.047 0 112.053 2.042zm15.914 13.02h-3.53v-5.727c0-1.368-.027-3.132-1.911-3.132-1.911 0-2.204 1.493-2.204 3.026v5.833h-3.53V9.038h3.39v1.557h.05c.473-.894 1.633-1.834 3.36-1.834 3.593 0 4.254 2.366 4.254 5.447v6.244z"></path>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                            <div className="overlay-description">
                                <p>
                                    Rohan Mathawan is a seasoned rummy player and contributor to GetMega's content. With extensive knowledge and experience in the field, Rohan has been part of the rummy community for over four years. His expertise in the game allows him to provide valuable feedback and insights, ensuring that the content is not only accurate but also engaging for rummy enthusiasts. His reviews help maintain the highest standards of content quality on the platform.
                                </p>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};


export default AboutAuthors;
